/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AssetProjectAsset } from '../models/AssetProjectAsset';
import type { AssetProjectAssetODataListResponse } from '../models/AssetProjectAssetODataListResponse';
import type { AssetProjectEditDTO } from '../models/AssetProjectEditDTO';
import type { AssetProjectStats } from '../models/AssetProjectStats';
import type { AssetProjectViewDTO } from '../models/AssetProjectViewDTO';
import type { AssetProjectViewDTOODataListResponse } from '../models/AssetProjectViewDTOODataListResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class AssetProjectService {

    /**
     * @param top The max number of records.
     * @param skip The number of records to skip.
     * @param filter A function that must evaluate to true for a record to be returned.
     * @param select Specifies a subset of properties to return. Use a comma separated list.
     * @param orderby Determines what values are used to order a collection of records.
     * @param expand Use to add related query data.
     * @param count Include the overall count
     * @returns AssetProjectViewDTOODataListResponse Success
     * @throws ApiError
     */
    public static getAssetProject(
top?: string,
skip?: string,
filter?: string,
select?: string,
orderby?: string,
expand?: string,
count?: string,
): CancelablePromise<AssetProjectViewDTOODataListResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/AssetProject',
            query: {
                '$top': top,
                '$skip': skip,
                '$filter': filter,
                '$select': select,
                '$orderby': orderby,
                '$expand': expand,
                '$count': count,
            },
        });
    }

    /**
     * @param formData 
     * @returns AssetProjectViewDTO Success
     * @throws ApiError
     */
    public static postAssetProject(
formData?: {
ClientId?: number;
Title?: string;
file?: Blob;
Status?: number;
},
): CancelablePromise<AssetProjectViewDTO> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/AssetProject',
            formData: formData,
            mediaType: 'multipart/form-data',
        });
    }

    /**
     * @param key 
     * @param top The max number of records.
     * @param skip The number of records to skip.
     * @param filter A function that must evaluate to true for a record to be returned.
     * @param select Specifies a subset of properties to return. Use a comma separated list.
     * @param orderby Determines what values are used to order a collection of records.
     * @param expand Use to add related query data.
     * @param count Include the overall count
     * @returns AssetProjectViewDTO Success
     * @throws ApiError
     */
    public static getAssetProject1(
key: number,
top?: string,
skip?: string,
filter?: string,
select?: string,
orderby?: string,
expand?: string,
count?: string,
): CancelablePromise<AssetProjectViewDTO> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/AssetProject/{key}',
            path: {
                'key': key,
            },
            query: {
                '$top': top,
                '$skip': skip,
                '$filter': filter,
                '$select': select,
                '$orderby': orderby,
                '$expand': expand,
                '$count': count,
            },
        });
    }

    /**
     * @param key 
     * @param requestBody 
     * @returns AssetProjectViewDTO Success
     * @throws ApiError
     */
    public static putAssetProject(
key: number,
requestBody?: AssetProjectEditDTO,
): CancelablePromise<AssetProjectViewDTO> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/AssetProject/{key}',
            path: {
                'key': key,
            },
            body: requestBody,
            mediaType: 'application/json;odata.metadata=minimal;odata.streaming=true',
        });
    }

    /**
     * @param key 
     * @returns any Success
     * @throws ApiError
     */
    public static deleteAssetProject(
key: number,
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/AssetProject/{key}',
            path: {
                'key': key,
            },
        });
    }

    /**
     * @param key 
     * @param top The max number of records.
     * @param skip The number of records to skip.
     * @param filter A function that must evaluate to true for a record to be returned.
     * @param select Specifies a subset of properties to return. Use a comma separated list.
     * @param orderby Determines what values are used to order a collection of records.
     * @param expand Use to add related query data.
     * @param count Include the overall count
     * @returns AssetProjectStats Success
     * @throws ApiError
     */
    public static getAssetProjectStats(
key: number,
top?: string,
skip?: string,
filter?: string,
select?: string,
orderby?: string,
expand?: string,
count?: string,
): CancelablePromise<AssetProjectStats> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/AssetProject/{key}/stats',
            path: {
                'key': key,
            },
            query: {
                '$top': top,
                '$skip': skip,
                '$filter': filter,
                '$select': select,
                '$orderby': orderby,
                '$expand': expand,
                '$count': count,
            },
        });
    }

    /**
     * @param key 
     * @returns binary Success
     * @throws ApiError
     */
    public static getAssetProjectExcelTemplate(
key: string,
): CancelablePromise<Blob> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/AssetProject/{key}/excelTemplate',
            path: {
                'key': key,
            },
        });
    }

    /**
     * @param key 
     * @returns binary Success
     * @throws ApiError
     */
    public static postAssetProjectScan(
key: number,
): CancelablePromise<Blob> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/AssetProject/{key}/scan',
            path: {
                'key': key,
            },
        });
    }

    /**
     * @param key 
     * @returns binary Success
     * @throws ApiError
     */
    public static getAssetProjectExport(
key: number,
): CancelablePromise<Blob> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/AssetProject/{key}/export',
            path: {
                'key': key,
            },
        });
    }

    /**
     * @param key 
     * @param addnew 
     * @param formData 
     * @returns any Success
     * @throws ApiError
     */
    public static postAssetProjectImport(
key: number,
addnew?: boolean,
formData?: {
file?: Blob;
},
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/AssetProject/{key}/import',
            path: {
                'key': key,
            },
            query: {
                'addnew': addnew,
            },
            formData: formData,
            mediaType: 'multipart/form-data',
        });
    }

    /**
     * @param key 
     * @param top The max number of records.
     * @param skip The number of records to skip.
     * @param filter A function that must evaluate to true for a record to be returned.
     * @param select Specifies a subset of properties to return. Use a comma separated list.
     * @param orderby Determines what values are used to order a collection of records.
     * @param expand Use to add related query data.
     * @param count Include the overall count
     * @returns AssetProjectAssetODataListResponse Success
     * @throws ApiError
     */
    public static getAssetProjectAssets(
key: number,
top?: string,
skip?: string,
filter?: string,
select?: string,
orderby?: string,
expand?: string,
count?: string,
): CancelablePromise<AssetProjectAssetODataListResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/AssetProject/{key}/assets',
            path: {
                'key': key,
            },
            query: {
                '$top': top,
                '$skip': skip,
                '$filter': filter,
                '$select': select,
                '$orderby': orderby,
                '$expand': expand,
                '$count': count,
            },
        });
    }

    /**
     * @param id 
     * @param key 
     * @param requestBody 
     * @returns AssetProjectAsset Success
     * @throws ApiError
     */
    public static putAssetProjectAssets(
id: string,
key: string,
requestBody?: AssetProjectAsset,
): CancelablePromise<AssetProjectAsset> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/AssetProject/{id}/assets/{key}',
            path: {
                'id': id,
                'key': key,
            },
            body: requestBody,
            mediaType: 'application/json;odata.metadata=minimal;odata.streaming=true',
        });
    }

}
