import { Button, Descriptions, DescriptionsProps, Drawer, Form, Input, message, Radio, Select, Slider, Space } from 'antd';
import React, { useEffect, useMemo } from 'react';
import { useAssetProjectServiceGetAssetProjectStats, useAssetProjectServicePutAssetProject, useInternalClientServiceGetInternalClient } from '../../../openapi/queries';
import { AssetProjectEditDTO, AssetProjectViewDTO } from '../../../openapi/requests';
import { AssetApprovalStatus, AssetApprovalStatusLabels, AssetProjectStatus, AssetProjectStatusLabels, AssetSourceType, AssetSourceTypeLabels } from '../../../models/enums';


const EditAssetProject: React.FC<{ isOpen: boolean, project: AssetProjectViewDTO | undefined, onClose: () => void }> = ({ isOpen, onClose, project }) => {
    const { data: clients } = useInternalClientServiceGetInternalClient({}, undefined, { enabled: isOpen });
    const { data: stats} = useAssetProjectServiceGetAssetProjectStats({key: project?.id ?? 0}, undefined, { enabled: isOpen });

    const [form] = Form.useForm<AssetProjectEditDTO>();

    const {mutate, isPending, isSuccess} = useAssetProjectServicePutAssetProject();

    const onSubmit = () => {
        form.submit();
    };

    useEffect(() => {
        if (project) {
            form.setFieldsValue(project);
        }
    }, [project, form]);

    const onFinish = () => {
        if(project){
            const data = form.getFieldsValue();
            mutate({key: project.id, requestBody: data});
        }
    };

    useEffect(() => {
        if (isSuccess) {
            message.success("Project updated");
            onClose();
        }
    }, [isSuccess, onClose]);



    const sources: DescriptionsProps['items'] = useMemo(() => {
        let result:DescriptionsProps['items'] = [];

        if(stats){
            for (const key in stats.sources) {
                if (Object.prototype.hasOwnProperty.call(stats.sources, key)) {
                    const element = stats.sources[key];
                    
                    result.push({key: 'source-' + key, label: AssetSourceTypeLabels[key as unknown as AssetSourceType], children: element})
                }
            }
        }

        return result;
    }, [stats]);

    const statuses: DescriptionsProps['items'] = useMemo(() => {
        let result:DescriptionsProps['items'] = [];

        if(stats){
            for (const key in stats.statuses) {
                if (Object.prototype.hasOwnProperty.call(stats.statuses, key)) {
                    const element = stats.statuses[key];
                    
                    result.push({key: 'status-' + key, label: AssetApprovalStatusLabels[key as unknown as AssetApprovalStatus], children: element})
                }
            }
        }

        return result;
    }, [stats]);

    return (
        <>
            <Drawer
                title="Update project"
                width={720}
                onClose={onClose}
                open={isOpen}
                bodyStyle={{ paddingBottom: 80 }}
                extra={
                    <Space>
                        <Button onClick={onClose}>Cancel</Button>
                        <Button onClick={onSubmit} type="primary" loading={isPending}>
                            Submit
                        </Button>
                    </Space>
                }
            >
                <Form
                    name="basic"
                    form={form}
                    layout="vertical"
                    onFinish={onFinish}>

                    <Form.Item rules={[{ required: true }]} name='clientId' label="Client">
                        <Select options={clients?.value.map(e => ({ label: e.name, value: e.id }))} />
                    </Form.Item>

                    <Form.Item rules={[{ required: true }]} name='title' label="Title">
                        <Input />
                    </Form.Item>

                    <Form.Item rules={[{ required: true }]} name='status' label="Status" >
                        <Radio.Group
                            options={[
                                { label: AssetProjectStatusLabels[AssetProjectStatus.New], value: AssetProjectStatus.New },
                                { label: AssetProjectStatusLabels[AssetProjectStatus.Quote], value: AssetProjectStatus.Quote },
                                { label: AssetProjectStatusLabels[AssetProjectStatus.InProduction], value: AssetProjectStatus.InProduction },
                            ]}
                            optionType="button"
                            buttonStyle="solid"
                        />
                    </Form.Item>

                    <Descriptions title="Assets" layout='horizontal' column={1} items={[
                        {
                            label: 'Asset count',
                            key: 'count',
                            children: stats?.assetCount
                        },
                        {
                            label: 'Complexity',
                            key: 'Complexity',
                            children: <Slider style={{width: '100%'}} marks={{0: 'Simple', 5: 'Complex', 10: 'Very complex'}}  max={10} min={0} included={false} value={stats?.averageComplexity} />
                        }

                    ]} />
                    <Descriptions title="Source" layout='horizontal' column={1} items={sources} />
                    <Descriptions title="Status" layout='horizontal' column={1} items={statuses} />
                </Form>
            </Drawer>
        </>

    );
};

export default EditAssetProject;