import { FC, PropsWithChildren, useCallback, useContext, useEffect, useMemo, useRef, useState } from "react"
import { Wizard, useWizard } from "react-use-wizard"
import { Loader } from "../../layout/elements/Loader";
import { AnimatePresence, motion } from "framer-motion";
import { AngleSelector } from "./AngleSelector";
import { useProjectFolderServiceGetProjectFolder, useStillImageSubmissionPlanServiceGetStillImageSubmissionPlan, useStillImageSubmissionPlanServiceGetStillImageSubmissionPlanKey, useUserServiceGetUserRenderlimits, useUserServiceGetUserRenderlimitsKey } from "../../../openapi/queries";
import { BatchCutoutViewDTO, CombinedResultDTO, CutoutService, OrderPostProcessingFeatureDTO, ProjectFolderService, SceneModifierTargetDTO, ScenePlacementpointDTO, SceneSurfaceDTO, TemplateViewDTO } from "../../../openapi/requests";
import { GenerateODataFilter } from "../../../helpers/odataFunctions";
import { ConfigContext, FeatureFlags } from "../../../contexts/ConfigContext";
import { AuthContext } from "../../../contexts/AuthContext";
import { CartContext, getRenderTemplates, OrderConfiguration } from "../../../contexts/CartContext";
import { AssetTemplate, CutoutAngle } from "../../../types/types";
import { MaterialSelection, ModifierSelection, ModelPlacement, StillImageConfiguration, Camera } from "../../../models/StillImageConfiguration";
import ReactGA from "react-ga4";

import "./BatchCutout.scss";
import { useDebouncedCallback } from "use-debounce";

interface BatchCutoutProps {
    isOpen: boolean
    templates: TemplateViewDTO[]
    onComplete: () => void
}

export const BatchCutout: FC<BatchCutoutProps> = ({ isOpen, templates, onComplete }) => {

    const previousStep = useRef<number>(0);
    const [isWorking, setIsWorking] = useState(false)

    const [projectName, setProjectName] = useState<string | undefined>("");
    const [folderId, setFolderId] = useState<number | undefined>(undefined)
    const [folderName, setFolderName] = useState<string | undefined>("");
    const [reference, setReference] = useState<string | undefined>("");
    const [entries, setEntries] = useState<string[]>([]);
    const [results, setResults] = useState<BatchCutoutViewDTO[]>([]);
    const [angles, setAngles] = useState<CutoutAngle[]>([])
    const [hideProgress, setHideProgress] = useState(true)

    const { userClient, featureFlags } = useContext(ConfigContext);

    const { createNewCutoutProject, currentOrder, currentProject, submitRendering, updateCutoutCurrentOrder, setCurrentProject } = useContext(CartContext);
    const { data: userRenderLimits } = useUserServiceGetUserRenderlimits([useUserServiceGetUserRenderlimitsKey], { enabled: userClient.id !== 0 })
    const { data: stillImageSubmissionPlans } = useStillImageSubmissionPlanServiceGetStillImageSubmissionPlan({ filter: GenerateODataFilter([{ name: 'client', property: 'clientid', values: [userClient.id], type: 'select' }]) }, [useStillImageSubmissionPlanServiceGetStillImageSubmissionPlanKey], { enabled: userClient.id !== 0 });
   // const { data: postProcessingFeatures } = useInternalPostProcessingFeatureServiceGetInternalPostProcessingFeature({}, [useInternalPostProcessingFeatureServiceGetInternalPostProcessingFeatureKey]);

    const cutoutTemplates = useMemo(() => {
        return templates?.filter(e => e.editorModule.toLowerCase() === "cutout" || e.templatetype.internalName === "3dsmax_360spin") ?? [];
    }, [templates])

    const validAssets = useMemo(() => {
        return results?.filter(x => x.batchCutoutStatus === 1).map(x => x.result) ?? []
    }, [results])

    const getAssetTemplates = useCallback((availableTemplates: TemplateViewDTO[]) => {

        const assetTemplates: AssetTemplate[] = [];
        validAssets.forEach(asset => {
            const renderTemplates = getRenderTemplates(availableTemplates, asset);
            if (renderTemplates) {

                for (let i = 0; i < renderTemplates.length; i++) {
                    let renderTemplate = renderTemplates[i];
                    if (renderTemplate) {
                        const assetTemplate = assetTemplates.find(x => x.template.id === renderTemplate?.id);
                        if (assetTemplate) {
                            assetTemplate.assets = [...assetTemplate.assets, asset];
                        } else {
                            assetTemplates.push({ template: { ...renderTemplate }, assets: [asset] });
                        }
                    }

                }
            }
        });

        return assetTemplates;
    }, [validAssets]);

   /* const getPostProcessingFeatures = useCallback((keys: string[]): OrderPostProcessingFeatureDTO[] => {
        return postProcessingFeatures.value.filter(x => keys.includes(x.internalName));
    }, [postProcessingFeatures])*/

    const createCutoutProject = useCallback(async () => {

        if (!currentProject) {
            let localFolderId = (folderId) ?? folderId;

            if (folderName && folderName.length > 0) {
                let folder = await ProjectFolderService.postProjectFolder({ name: folderName, id: 0 });
                localFolderId = folder.id;
                setFolderId(localFolderId);
            }

            if (!projectName)
                return;

            await createNewCutoutProject(projectName, reference, localFolderId, {});
        }
    }, [currentProject, folderId, folderName, projectName, createNewCutoutProject, reference])

    const mapModel = (placementPoint: ScenePlacementpointDTO, assets: CombinedResultDTO[]): ModelPlacement => {
        return {
            FeatureSelections: [],
            ModelSelections: [],
            SurfaceSelections: [],
            Name: placementPoint.name,
            MoodboardPosition: {
                x1: 0,
                x2: 0,
                y1: 0,
                y2: 0
            },
            Value: assets.filter(x => x.assetType.toLowerCase() === "model")[0].name,
            Values: assets.filter(x => x.assetType.toLowerCase() === "model").map(x => ({ value: x.name, filenamePrefix: x.metadata?.find(m => m.name.toLowerCase() === 'ean')?.value }))
        };
    };

    const mapMaterial = (surface: SceneSurfaceDTO, assets: CombinedResultDTO[]): MaterialSelection => {
        return {
            Name: surface.name,
            MoodboardPosition: {
                x1: 0,
                x2: 0,
                y1: 0,
                y2: 0
            },
            Value: '',
            Values: assets.filter(x => x.assetType.toLowerCase() === "material").map(x => ({ value: x.name, filenamePrefix: x.metadata?.find(m => m.name.toLowerCase() === 'ean')?.value }))
        }
    }

    const mapModifier = (modifiertarget: SceneModifierTargetDTO, assets: CombinedResultDTO[]): ModifierSelection => {
        return {
            Name: modifiertarget.name,
            MoodboardPosition: {
                x1: 0,
                x2: 0,
                y1: 0,
                y2: 0
            },
            Value: '',
            Values: assets.filter(x => x.assetType.toLowerCase() === "modifier").map(x => ({ value: x.name, filenamePrefix: x.metadata?.find(m => m.name.toLowerCase() === 'ean')?.value }))
        }
    }

    const createCutoutConfigurations = useCallback(async (validAngles: CutoutAngle[], validAssetTemplates: AssetTemplate[]) => {

        const configurations: OrderConfiguration[] = [];

        validAssetTemplates.forEach((validAssetTemplate, index) => {

            let angles = validAngles.filter(x => x.template?.id === validAssetTemplate.template.id);

            const cameras: Camera[] = angles.map(angle => { return { Name: angle.id, Variants: [{ DeliveryFilenameTemplate: angle.deliveryFileNameTemplate, Lighting: undefined }] } as Camera });
            if (cameras.length > 0) {
                let stillImageConfiguration: StillImageConfiguration = {} as StillImageConfiguration;

                stillImageConfiguration = {
                    Cameras: cameras,                    
                    Animations: [],
                    Moodboard: {
                        BackgroundColor: "",
                        PreviewPosition: { x1: 0, x2: 0, y1: 0, y2: 0 }
                    },
                    ModelSelections: validAssetTemplate.template.scene.placementpoints.map(x => mapModel(x, validAssetTemplate.assets)),
                    ModifierSelections: validAssetTemplate.template.scene.modifierTargets.map(x => mapModifier(x, validAssetTemplate.assets)),
                    SurfaceSelections: validAssetTemplate.template.scene.surfaces.map(x => mapMaterial(x, validAssetTemplate.assets)),
                    PropsetSelections: [],
                    camera: ''
                }

                configurations.push({ template: validAssetTemplate.template, configuration: stillImageConfiguration });
            }
        });

        return configurations;
    }, [])

    const onEntriesValidate = async (projectName: string | undefined, reference: string | undefined, folderId: number | null | undefined, folderName: string | null | undefined, entries: string[]) => {
        setIsWorking(true);

        setProjectName(projectName);
        setReference(reference);
        setEntries(entries);
        setFolderName(folderName ?? undefined);
        setFolderId(folderId ?? undefined);
    }

    const onEntriesValidated = (results: BatchCutoutViewDTO[]) => {
        setResults(results);
        setIsWorking(false);
    }

    const onChange = async (angles: CutoutAngle[]) => {

        setIsWorking(true);

        const validAssetTemplates = getAssetTemplates(cutoutTemplates);
      //  const postProcessFeatures = getPostProcessingFeatures(['dl_post_py_center_in_frame']);
        const configurations = await createCutoutConfigurations(angles, validAssetTemplates);
        
        var pathPostProcessFeature = validAssetTemplates[0].template.postProcessingFeatures.find(x => x.id === 12);
        var orderlinePostProcess = pathPostProcessFeature as OrderPostProcessingFeatureDTO;

        await updateCutoutCurrentOrder({ configurations: configurations, orderlinePostProcesses: [orderlinePostProcess] })

        setAngles(angles)

        setIsWorking(false);

    }

    const onSubmitRendering = async (planName: string) => {

        setIsWorking(true);
        setHideProgress(false);

        const stillImageSubmissionPlan = stillImageSubmissionPlans.value.find(e => e.name?.toLowerCase().includes(planName) && e.name?.toLowerCase().includes("cutout"));
        if (stillImageSubmissionPlan) {

            ReactGA.event('submit_render', { type: 'batchcutout' });

            //Validate limits
            let limitExceeded = false;

            if (currentOrder) {
                currentOrder.configurations.forEach(config => {

                    if (limitExceeded) {
                        return;
                    }

                    let limitSubmissionPlan = stillImageSubmissionPlans.value.find(e => e.name?.toLowerCase().includes(planName) && e.name?.toLowerCase().includes("cutout"));

                    //Spin 360
                    let isSpin360 = (config.template.templatetype.internalName === "3dsmax_360spin");
                    if (isSpin360)
                        limitSubmissionPlan = stillImageSubmissionPlans.value.find(e => e.name?.toLowerCase().includes("spin360"));

                    if (limitSubmissionPlan) {
                        let userLimit = userRenderLimits.find(x => x.submissionPlan.id === limitSubmissionPlan?.id);
                        let maxLimit = limitSubmissionPlan.defaultRenderLimit;
                        if (userLimit) {
                            maxLimit = userLimit.limit;
                        }

                        let cameraCount = config.configuration.Cameras?.length ?? 0;
                        let modelCount = config.configuration.ModelSelections[0].Values?.length ?? 0;

                        limitExceeded = ((cameraCount * modelCount) > maxLimit);
                    }
                });

                await submitRendering(stillImageSubmissionPlan)

                setIsWorking(false);
                setHideProgress(true);
                onComplete();
            }
        }
    }  

    return (
        <>
            <div className="batchcutout">
                {isWorking && <Loader hideProgress={!hideProgress}></Loader>}

                <Wizard wrapper={<AnimatePresence initial={false} mode="sync" />} startIndex={0} >
                    <AnimatedStep key="batchcutougcreate" previousStep={previousStep}><BatchCutoutCreate projectName={projectName} reference={reference} entries={entries} featureFlags={featureFlags} folderId={folderId} folderName={folderName} onEntriesValidate={onEntriesValidate} onEntriesValidated={onEntriesValidated} /></AnimatedStep>
                    <AnimatedStep key="batchcutougverify" previousStep={previousStep}><BatchCutoutVerify results={results ?? []} onCreateProject={createCutoutProject} /></AnimatedStep>
                    <AnimatedStep key="batchcutougangles" previousStep={previousStep}><BatchCutoutAngles assets={validAssets ?? []} templates={templates ?? []} onChange={onChange} onSubmitRendering={onSubmitRendering} /></AnimatedStep>
                </Wizard>
            </div>

        </>
    )

}

type AnimatedStepProps = {
    previousStep: React.MutableRefObject<number>;
};

const AnimatedStep: FC<PropsWithChildren<AnimatedStepProps>> = ({ children, previousStep: previousStepIndex }) => {

    const { activeStep } = useWizard();

    const variants = {
        enter: (direction: number) => {
            return {
                x: direction > 0 ? "100%" : "-100%",
                opacity: 0
            }
        },
        center: {
            zIndex: 1,
            x: 0,
            opacity: 1
        },
        exit: (direction: number) => {
            return {
                x: direction < 0 ? "100%" : "-100%",
                opacity: 0
            }
        }
    };

    const transition = {
        x: {
            type: "spring",
            stiffness: 300,
            damping: 30
        }
    }

    useEffect(() => {
        return () => {
            previousStepIndex.current = activeStep;
        };
    }, [activeStep, previousStepIndex]);

    return (
        <motion.div
            custom={activeStep - previousStepIndex.current}
            variants={variants}
            initial="enter"
            animate="center"
            exit="exit"
            transition={transition}>
            {children}
        </motion.div>
    );
}

interface BatchCutoutCreateProps {
    projectName: string | undefined,
    reference: string | undefined,
    entries: string[],
    folderId: number | undefined,
    folderName: string | undefined,
    featureFlags: FeatureFlags,
    onEntriesValidate: (projectName: string | undefined, reference: string | undefined, folderId: number | undefined, folderName: string | undefined, entries: string[]) => void
    onEntriesValidated: (results: BatchCutoutViewDTO[]) => void
}

export const BatchCutoutCreate: FC<BatchCutoutCreateProps> = ({ projectName, reference, entries, folderId, folderName, featureFlags, onEntriesValidate, onEntriesValidated }) => {

    const { nextStep } = useWizard();

    const { hasPermission } = useContext(AuthContext);
    const { currentClient, odataFilters } = useContext(ConfigContext);
    const { currentProject } = useContext(CartContext);

    const [localProjectName, setLocalProjectName] = useState<string | undefined>("");
    const [localReference, setLocalReference] = useState<string | undefined>("");
    const [localEntries, setLocalEntries] = useState<string[]>([]);
    const [localFolderName, setLocalFolderName] = useState<string | undefined>("");
    const [localFolder, setLocalFolder] = useState<number | undefined>(undefined);

    useEffect(() => {
        setLocalProjectName(projectName);
        setLocalFolder(folderId);
        setLocalFolderName(folderName);   
        setLocalEntries(entries);
        setLocalReference(reference);
    }, [entries, folderId, folderName, projectName, reference])  

    const filter = useMemo(() => {

        let combinedFilters = [...odataFilters];

        if (!hasPermission('ViewAllOrders')) {
            combinedFilters.push({ name: "Owner", property: "IsOwner", type: "", values: ["true"] });
        }

        return GenerateODataFilter(combinedFilters);
    }, [hasPermission, odataFilters])

    const { data: folders } = useProjectFolderServiceGetProjectFolder({ filter });

    const validateEntries = async () => {

        let folderId: number | undefined = undefined;
        if (localFolder) {
            folderId = localFolder;
        }       

        onEntriesValidate(localProjectName, localReference, folderId, localFolderName, localEntries);

        const results = await CutoutService.postCutoutBatchcutoutResult(undefined, undefined, GenerateODataFilter([{ name: 'client', property: 'clientid', values: [currentClient.id], type: 'select' }]), undefined, undefined, undefined, undefined, localEntries);
        onEntriesValidated(results);
        nextStep();
    }

    const handleEntries = (value: string) => {

        if (!value || value.length === 0)
            return [];

          value = value.replace(/\s\s+/g, " ");
          value = value.replace(/,/g, "");
          value = value.replace(/ /g, "\n");

          let entriesValues = value.split("\n").filter(uniqueEntries);

          return entriesValues;
    }
    
    const uniqueEntries = (value: string, index: number, array: Array<string>) => {
        return array.indexOf(value) === index;
    }

    const canVerify = useMemo(() => {
        return (localProjectName && ((localFolderName || localFolder) || (!localFolderName && !localFolder)) && localEntries.length > 0);
    }, [localEntries, localFolder, localFolderName, localProjectName])

    return (
        <div className="batchcutout-step">
            <div className="batchcutout-create">
                <div className="batchcutout-projectname">
                    <label className="header">
                        <h1>Projectname</h1>
                    </label>
                    <input id="projectName" name="projectName" type="text" placeholder="Name..." disabled={currentProject !== undefined} value={localProjectName} onChange={e => setLocalProjectName(e.target.value)}></input>
                </div>
                <div className="batchcutout-reference">
                    <label className="header">
                        <h1>Reference</h1>
                    </label>
                    <input id="reference" name="reference" type="text" placeholder="Reference..." disabled={currentProject !== undefined}  value={localReference} onChange={e => setLocalReference(e.target.value)}></input>
                </div>
                <div className="batchcutout-folder">
                    {featureFlags.ProjectFolders && <>
                        <h1>Add to folder <span className='faded'>(Optional)</span></h1>
                        <select value={localFolder} disabled={currentProject !== undefined}  onChange={e => {
                            setLocalFolderName("");
                            setLocalFolder(parseInt(e.target.value, 10))
                        }}>
                            <option key={''} className='placeholder' value=''>Select existing folder</option>
                            {folders?.value.map(e => <option key={e.id} value={e.id}>{e.name}</option>)}
                        </select>

                        <h2>Or</h2>

                        <input type='text' disabled={currentProject !== undefined} placeholder='Type new folder name...' value={localFolderName} onChange={e => {
                            setLocalFolderName(e.target.value)
                            setLocalFolder(0)
                        }} />
                    </>
                    }
                </div>

                <div className="batchcutout-list-wrapper">
                    <div className="batchcutout-list">
                        <label className="header">
                            <h1>EANs (Unique)</h1>
                        </label>
                        <textarea id="projectEans" rows={20} value={localEntries.join("\n")} onChange={e => {
                            setLocalEntries(handleEntries(e.target.value))
                        }}>
                        </textarea>
                    </div>
                </div>
            </div>

            <div className="batchcutout-actions">
                <button onClick={validateEntries} disabled={!canVerify}>Verify Entries</button>
            </div>
        </div>
    )
}

interface BatchCutoutVerifyProps {
    results: BatchCutoutViewDTO[];
    onCreateProject: () => {}
}

export const BatchCutoutVerify: FC<BatchCutoutVerifyProps> = ({ results, onCreateProject }) => {

    const { previousStep, nextStep } = useWizard();

    ///TODO! filter entries into valid and invalid

    const validResults = useMemo(() => {
        return results?.filter(x => x.batchCutoutStatus === 1) ?? [];
    }, [results])

    const invalidResults = useMemo(() => {
        return results?.filter(x => x.batchCutoutStatus !== 1) ?? [];
    }, [results])

    const getStatus = (statusId: number) => {
        switch (statusId) {
            case 1: return "Available"
            case 2: return "Asset not enabled for production"
            case 3: return "Asset not found"
            case 4: return "Cut-out production not possible"
            case 5: return "Incomplete asset"
        }
    }

    const onNextStep = () => {
        onCreateProject();
        nextStep();
    }

    return (

        <div className="batchcutout-step">
            <div className="batchcutout-list-wrapper">
                <div className="batchcutout-list">
                    <label className="header">
                        <h1>Valid EANs</h1>
                    </label>
                    <table>
                        <colgroup>
                            <col width="100%" />
                        </colgroup>
                        <thead>
                            <tr>
                                <th align="left">Ean</th>
                            </tr>
                        </thead>
                        <tbody>
                            {validResults.map(result =>
                                <tr key={result.entry}><td>{result.entry}</td></tr>
                            )}
                        </tbody>
                    </table>
                </div>
                <div className="batchcutout-list">
                    <label className="header">
                        <h1>Invalid EANs</h1>
                    </label>
                    <table>
                        <colgroup>
                            <col width="50%" />
                        </colgroup>
                        <thead>
                            <tr>
                                <th align="left">Ean</th>
                                <th align="left">Reason</th>
                            </tr>
                        </thead>
                        <tbody>
                            {invalidResults.map(result =>
                                <tr key={result.entry}>
                                    <td>{result.entry}</td>
                                    <td>{getStatus(result.batchCutoutStatus)}</td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>
            </div>

            <div className="batchcutout-actions">
                <button onClick={previousStep}>Back</button>
                <button onClick={onNextStep} disabled={validResults.length === 0}>Select Angles</button>
            </div>

        </div>

    )
}

interface BatchCutoutAnglesProps {
    assets: CombinedResultDTO[],
    templates: TemplateViewDTO[],
    onChange: (angles: CutoutAngle[]) => void,
    onSubmitRendering: (planName: string) => void
}

export const BatchCutoutAngles: FC<BatchCutoutAnglesProps> = ({ assets, templates, onSubmitRendering, onChange }) => {

    const { previousStep } = useWizard();


    const { hasPermission } = useContext(AuthContext);

    const [selectedAngles, setSelectedAngles] = useState<CutoutAngle[]>([]);

    const onAngleChange = async (angles: CutoutAngle[]) => {
        setSelectedAngles(angles)
        onChange(angles)
    }

    const angleChangeDebounce = useDebouncedCallback((angles) => onAngleChange(angles), 500);

    return (

        <div className="batchcutout-step">
            <AngleSelector assets={assets} templates={templates} onAnglesChange={angleChangeDebounce} />
            <div className="batchcutout-actions">
                <button onClick={previousStep}>Back</button>
                {hasPermission("CreateOrders") && (hasPermission('FinalRender')) && <button onClick={() => onSubmitRendering("final")} disabled={selectedAngles.length === 0}>Final Render</button>}
                {hasPermission("CreateOrders") && (hasPermission('PriorityRender')) && <button onClick={() => onSubmitRendering("priority")} disabled={selectedAngles.length === 0}>Priority Render</button>}
            </div>
        </div>

    )
}

