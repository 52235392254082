import React, { useCallback, useContext, useState } from 'react';
import Overlay from '../layout/Overlay';
import { AssetCartContext } from '../../contexts/AssetCartContext';
import { ReactComponent as CartIcon } from '../../assets/icons/cart.svg';
import './AssetCart.scss';
import AssetList from '../layout/AssetList';
import { ReactComponent as DeleteIcon } from '../../assets/icons/delete.svg';
import { ReactComponent as EditIcon } from '../../assets/icons/edit.svg';
import { AssetCartDTO } from '../../openapi/requests';
import { useDebouncedCallback } from 'use-debounce';
import Select, { components, MultiValue, OptionProps, StylesConfig } from "react-select";
import BottomDrawer from '../layout/BottomDrawer';
import { BASE_PATH } from '../..';
import saveAs from 'file-saver';
import { Confirm } from '../layout/Dialog';
import ReactGA from "react-ga4";

const OptionComponent = (props: OptionProps<AssetCartDTO>) => {
    return (
        <components.Option {...props}>{props.data.name} ({props.data.items.length})</components.Option>
    );
};

const colourStyles: StylesConfig<AssetCartDTO, true> = {
    control: (styles) => ({
        ...styles,
        borderRadius: '0',
        fontSize: '12px',
        minHeight: '32px',
        width: '180px'
    }),
    indicatorSeparator: (styles) => ({
        ...styles,
        display: 'none',
    }),
    dropdownIndicator: (styles) => ({
        ...styles,
        padding: '0px 8px 0px 0px'
    }),
    noOptionsMessage: (styles) => ({
        ...styles,
        fontSize: '12px',
    }),
    option: (styles, { isFocused, isSelected }) => ({
        ...styles,
        display: 'flex',
        alignItems: 'center',
        fontSize: '12px',
        color: (isSelected) ? '#FFF' : (isFocused) ? '#FFF' : '#000',
        backgroundColor: (isSelected) ? '#000' : (isFocused) ? 'rgba(0,0,0,.26)' : '#FFF',
        ':active': {
            ...styles[':active'],
            color: (isSelected) ? '#FFF' : (isFocused) ? '#FFF' : '#000',
            backgroundColor: (isSelected) ? '#000' : (isFocused) ? 'rgba(0,0,0,.26)' : '#FFF',
        }
    })
};

const AssetCart: React.FC = () => {
    const { currentCart, carts, setCurrentCart, isLoading, createNewCart } = useContext(AssetCartContext);
    const [isOpen, setIsOpen] = useState(false);
    const [isCreateOpen, setIsCreateOpen] = useState(false);
    const [name, setName] = useState("");
    const [isDownloading, setIsDownloading] = useState(false);


    const handleOpen = useCallback(() => {
        setIsOpen(true);
    }, []);

    const handleClose = useCallback(() => {
        setIsOpen(false);
    }, []);

    const handleChange = useCallback((option: AssetCartDTO | MultiValue<AssetCartDTO>) => {
        setCurrentCart(option as AssetCartDTO);
    }, [setCurrentCart]);

    const handleCancel = useCallback(() => {
        setIsCreateOpen(false);
    }, []);

    const handleOpenCreate = useCallback(() => {
        setIsCreateOpen(true);
    }, []);

    const handleCreate = useCallback(async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        createNewCart(name).then(() => {
            setIsCreateOpen(false);
        })
    }, [createNewCart, name]);

    const handleDownload = async () => {
        ReactGA.event('download_cart_powerpoint', {});
        const token = localStorage.getItem('accessToken');
        let filename = '';
        setIsDownloading(true);

        fetch(`${BASE_PATH}/assetcart/powerpoint`, {
            method: 'get',
            headers: {
                "Authorization": 'Bearer ' + token
            }
        }).then(res => {
            const header = res.headers.get('Content-Disposition');
            setIsDownloading(false);
            if(header){
                const parts = header.split(';');
                filename = parts[1].split('=')[1];
                filename = filename.replaceAll("\"", "");
            }
            return res.blob();
        }).then(blob => {
            saveAs(blob, filename);
        });
    }

    return (
        <>
            <div className='quick-assetcart'>
                <Select
                    placeholder={<div className='placeholder' onClick={handleOpen}><CartIcon /><span className='badge'>{currentCart?.items?.length ?? 0}</span>{currentCart?.name}</div>}
                    components={{ Option: OptionComponent }}
                    closeMenuOnSelect={true}
                    hideSelectedOptions={false}
                    theme={(theme) =>
                    ({
                        ...theme,
                        colors: {
                            ...theme.colors,
                            primary: 'black',
                            primary25: 'black',
                        }
                    })
                    }
                    value={currentCart}
                    onChange={handleChange}
                    options={carts}
                    styles={colourStyles}
                    openMenuOnClick={false}
                    isSearchable={false}
                    isClearable={false}
                    getOptionValue={e => e.id.toString()}
                    controlShouldRenderValue={false}
                />
            </div>

            <Overlay open={isOpen} onClose={handleClose} className='assetcart'>
                <div className='assetcart-header'>
                    <h1>Cart</h1>
                    <button disabled={isDownloading} className='secondary button' onClick={handleDownload}>Download as PowerPoint</button>
                    <button className='secondary button' onClick={handleOpenCreate}>Add new cart</button>
                </div>
                {carts.map(cart => <AssetCartGroup cart={cart} key={cart.id} />)}
            </Overlay>

            <BottomDrawer className='createcart' open={isCreateOpen} onClose={handleCancel} size={420}>
                <form onSubmit={handleCreate}>
                    <h1>Name your cart</h1>
                    <input type='text' required placeholder='Type name...' value={name} onChange={e => setName(e.target.value)} />

                    <button disabled={isLoading} type='submit'>Create</button>
                </form>
            </BottomDrawer>
        </>
    )
};


const AssetCartGroup: React.FC<{ cart: AssetCartDTO }> = ({ cart }) => {
    const { isLoading, removeFromCart, renameCart, deleteCart } = useContext(AssetCartContext);
    const [editing, setEditing] = useState(false);

    const debounced = useDebouncedCallback(
        (value) => {
            renameCart(cart, value);
        },
        1000,
        { maxWait: 5000 }
    );

    return (<div className='assets'>
        <h2>
            {editing ? <input defaultValue={cart.name} onChange={(e) => debounced(e.target.value)} /> : cart.name}
            <span>{cart.items.length} items</span>
            <button onClick={() => setEditing(e => !e)}><EditIcon /></button>
            <Confirm text='' title='Are you sure?' onConfirm={() => deleteCart(cart)} >
                <button><DeleteIcon /></button>
            </Confirm>
        </h2>
        <AssetList color='dark' assets={cart.items ?? []} displayMode='medium' loading={isLoading} renderOverlay={e => <div className='buttons'><button onClick={() => removeFromCart(e)}><DeleteIcon /></button></div>} />
    </div>);
}


export default AssetCart;