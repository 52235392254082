/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { BatchCutoutViewDTO } from '../models/BatchCutoutViewDTO';
import type { CombinedResultDTOODataListResponse } from '../models/CombinedResultDTOODataListResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class CutoutService {

    /**
     * @param name 
     * @param top The max number of records.
     * @param skip The number of records to skip.
     * @param filter A function that must evaluate to true for a record to be returned.
     * @param select Specifies a subset of properties to return. Use a comma separated list.
     * @param orderby Determines what values are used to order a collection of records.
     * @param expand Use to add related query data.
     * @param count Include the overall count
     * @returns any Success
     * @throws ApiError
     */
    public static getCutoutFilter(
name?: string,
top?: string,
skip?: string,
filter?: string,
select?: string,
orderby?: string,
expand?: string,
count?: string,
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/Cutout/filter',
            query: {
                'name': name,
                '$top': top,
                '$skip': skip,
                '$filter': filter,
                '$select': select,
                '$orderby': orderby,
                '$expand': expand,
                '$count': count,
            },
        });
    }

    /**
     * @param query 
     * @param top The max number of records.
     * @param skip The number of records to skip.
     * @param filter A function that must evaluate to true for a record to be returned.
     * @param select Specifies a subset of properties to return. Use a comma separated list.
     * @param orderby Determines what values are used to order a collection of records.
     * @param expand Use to add related query data.
     * @param count Include the overall count
     * @returns CombinedResultDTOODataListResponse Success
     * @throws ApiError
     */
    public static getCutout(
query?: string,
top?: string,
skip?: string,
filter?: string,
select?: string,
orderby?: string,
expand?: string,
count?: string,
): CancelablePromise<CombinedResultDTOODataListResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/Cutout',
            query: {
                'query': query,
                '$top': top,
                '$skip': skip,
                '$filter': filter,
                '$select': select,
                '$orderby': orderby,
                '$expand': expand,
                '$count': count,
            },
        });
    }

    /**
     * @param top The max number of records.
     * @param skip The number of records to skip.
     * @param filter A function that must evaluate to true for a record to be returned.
     * @param select Specifies a subset of properties to return. Use a comma separated list.
     * @param orderby Determines what values are used to order a collection of records.
     * @param expand Use to add related query data.
     * @param count Include the overall count
     * @param requestBody 
     * @returns BatchCutoutViewDTO Success
     * @throws ApiError
     */
    public static postCutoutBatchcutoutResult(
top?: string,
skip?: string,
filter?: string,
select?: string,
orderby?: string,
expand?: string,
count?: string,
requestBody?: Array<string>,
): CancelablePromise<Array<BatchCutoutViewDTO>> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/Cutout/batchcutout/result',
            query: {
                '$top': top,
                '$skip': skip,
                '$filter': filter,
                '$select': select,
                '$orderby': orderby,
                '$expand': expand,
                '$count': count,
            },
            body: requestBody,
            mediaType: 'application/json;odata.metadata=minimal;odata.streaming=true',
        });
    }

}
