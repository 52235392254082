import { notification } from "antd";
import globalAxios, { AxiosError, AxiosResponse } from 'axios';
import jwt_decode from 'jwt-decode';
import React, { FC, createContext, useCallback, useContext, useEffect, useState } from "react";
import { BASE_PATH } from "..";
import { AuthenticateService } from "../openapi/requests";
import { DialogContext } from "./DialogContext";
import { useNavigate } from "react-router-dom";

// eslint-disable-next-line no-restricted-globals
export const isDevSite = location.hostname.includes("internal") || location.hostname.includes("localhost");

export type Role = "Cadesign";

export type Permission = "UserAdmin" | "ViewOrders" | "ViewAllOrders" | "CreateOrders" | "ViewStatistics" | "ApproveOrders" | "FinalRender" | "PriorityRender" | "PreviewRender" | "RequestManualAdjustments" | "RenderAnimatedCamera" | "RenderVideo" | "Search";

const AzureLoginRedirect = (isDevSite ? process.env.REACT_APP_AZUREAD_LOGINREDIRECTURL_DEV : process.env.REACT_APP_AZUREAD_LOGINREDIRECTURL_PROD) || "";
const AzureLogoutRedirect = (isDevSite ? process.env.REACT_APP_AZUREAD_LOGOUTREDIRECTURL_DEV : process.env.REACT_APP_AZUREAD_LOGOUTREDIRECTURL_PROD) || "";

export const AzureLoginUrl = `https://login.microsoftonline.com/organizations/oauth2/v2.0/authorize?&client_id=${process.env.REACT_APP_AZUREAD_CLIENTID}&response_type=code&redirect_uri=${encodeURIComponent(AzureLoginRedirect)}&response_mode=query&scope=https://graph.microsoft.com/user.read`;
export const AzureLogoutUrl = `https://login.microsoftonline.com/common/oauth2/logout?client_id=${process.env.REACT_APP_AZUREAD_CLIENTID}&post_logout_redirect_uri=${encodeURIComponent(AzureLogoutRedirect)}`

export interface AuthContextState {
    loading: boolean;
    isLoggedIn: boolean;
    username: string;
    permissions: Permission[];
    roles: Role[];
    hasPermission: (permission: Permission) => boolean;
    hasRole: (role: Role) => boolean;
    logOut: () => void;
    logIn: () => void;
};

const contextDefaultValues: AuthContextState = {
    loading: true,
    isLoggedIn: false,
    username: '',
    permissions: [],
    roles: [],
    hasPermission: () => false,
    hasRole: () => false,
    logOut: () => { },
    logIn: () => { }
};

export const AuthContext = createContext<AuthContextState>(
    contextDefaultValues
);

let oidcPromise: Promise<any> | undefined = undefined;

export const AuthProvider: FC<{ children: React.ReactNode }> = ({ children }) => {
    const [loading, setLoading] = useState(contextDefaultValues.loading);
    const [isLoggedIn, setIsLoggedIn] = useState(contextDefaultValues.isLoggedIn);
    const [username, setUsername] = useState(contextDefaultValues.username);
    const [authmethod, setAuthmethod] = useState("");
    const [permissions, setPermissions] = useState(contextDefaultValues.permissions);
    const [roles, setRoles] = useState(contextDefaultValues.roles);
    const [api, contextHolder] = notification.useNotification();
    const { message, error } = useContext(DialogContext);
    const navigate = useNavigate();

    const logIn = useCallback(() => {
        let token = localStorage.getItem('accessToken');

        if (token) {
            let decoded = jwt_decode<{ unique_name: string, authmethod: string, Permission: string[] | string, role: string[] | string }>(token);

            setAuthmethod(decoded.authmethod)
            setUsername(decoded.unique_name);
            setPermissions((Array.isArray(decoded.Permission) ? decoded.Permission : [decoded.Permission]) as Permission[]);
            setRoles((Array.isArray(decoded.role) ? decoded.role : [decoded.role]) as Role[]);
            setIsLoggedIn(true);
        }
    }, []);

    const logOut = useCallback(() => {
        localStorage.removeItem('refreshToken');
        localStorage.removeItem('accessToken');
        setIsLoggedIn(false);

        if (authmethod === "azureAD") {
            //window.location.assign(AzureLogoutUrl);
        }
    }, [authmethod]);

    const handleRefreshFail = useCallback(() => {
        if (authmethod === "azureAD") {
            window.location.assign(AzureLoginUrl);
        } else {
            logOut();
        }
    }, [authmethod, logOut]);

    useEffect(() => {
        if (window.location.pathname === '/user/logout') {
            logOut();
            navigate("/");
        }

        if (window.location.pathname === '/user/signin-oidc' && oidcPromise === undefined) {
            const urlParams = new URLSearchParams(window.location.search);
            const code = urlParams.get('code');

            oidcPromise = globalAxios.post(`/api/Authenticate/callback?code=${code}`, undefined, { baseURL: BASE_PATH })
                .then(resp => {
                    if (resp.status === 200) {
                        const { accessToken, refreshToken } = resp.data;
                        localStorage.setItem('accessToken', accessToken);
                        localStorage.setItem('refreshToken', refreshToken);

                        logIn();
                        navigate("/");
                    }
                }).catch(reason => {
                    let axiosError = reason as AxiosError;

                    if (axiosError.response?.status === 400) {
                        error("We could not find you in our system, please contact us and we will respond to you asap.")
                    } else {
                        error("We could not log you in, please try again later.");
                    }
                    navigate("/");
                }).finally(() => {
                    oidcPromise = undefined;
                })
        }
    }, [error, logIn, logOut, message, navigate]);

    useEffect(() => {
        if (localStorage.getItem('accessToken')) {
            logIn();
        }

        let authTokenRequest: Promise<AxiosResponse> | null = null;

        // This function makes a call to get the auth token
        // or it returns the same promise as an in-progress call to get the auth token
        function getAuthToken() {
            if (!authTokenRequest) {
                authTokenRequest = globalAxios.post("/api/Authenticate/refresh", {
                    refreshToken: localStorage.getItem('refreshToken'),
                }, { baseURL: BASE_PATH });

                authTokenRequest.then(resp => {
                    if (resp.status === 200) {
                        const { accessToken } = resp.data;
                        localStorage.setItem('accessToken', accessToken);
                    } else {
                        handleRefreshFail();
                    }
                }).catch(() => {
                    handleRefreshFail();
                });

                authTokenRequest.then(resetAuthTokenRequest, resetAuthTokenRequest);
            }
            return authTokenRequest;
        }

        function resetAuthTokenRequest() {
            authTokenRequest = null;
        }

        globalAxios.interceptors.request.use(
            (config) => {
                const token = localStorage.getItem('accessToken');
                if (token) {
                    config.headers["Authorization"] = 'Bearer ' + token;
                }
                return config;
            },
            (error) => {
                return Promise.reject(error);
            }
        );

        globalAxios.interceptors.response.use(
            (res) => {
                return res;
            },
            async (err) => {
                const originalConfig = err.config;

                if (!(originalConfig.url.includes("/api/Authenticate") && !originalConfig.url.includes("/ping")) && err.response) {
                    // Access Token was expired
                    if (err.response.status === 401 && !originalConfig._retry) {

                        return getAuthToken().then(response => {
                            originalConfig._retry = true;
                            return globalAxios(originalConfig);
                        }).catch(_error => {
                            return Promise.reject(_error);
                        });
                    }
                }

                return Promise.reject(err);
            }
        );


        globalAxios.interceptors.response.use(
            (res) => {
                return res;
            },
            async (error: AxiosError) => {
                const status = error.response?.status || 0;

                if (status === 403) {
                    api.warning({
                        message: 'Access denied',
                    })
                } else if (status >= 500) {
                    api.warning({
                        message: 'An error has occured',
                    });
                }

                return Promise.reject(error);
            },
        );

        AuthenticateService.getApiAuthenticatePing().then(e => {
            setLoading(false);
        }).catch(() => {
            setLoading(false);
        });

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const hasPermission = useCallback((permission: Permission) => {
        return permissions.includes(permission);
    }, [permissions]);

    const hasRole = useCallback((role: Role) => {
        if (!roles)
            return false;

        return roles.includes(role);
    }, [roles]);

    if (loading) {
        children = null;
    }

    return (
        <AuthContext.Provider
            value={{
                loading,
                isLoggedIn,
                username,
                permissions,
                roles,
                hasPermission,
                hasRole,
                logOut,
                logIn
            }}
        >
            {contextHolder}
            {children}
        </AuthContext.Provider>
    );
};