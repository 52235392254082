import { AnimatePresence, motion, MotionConfig, Variants } from "framer-motion";
import React, { useContext, useState } from 'react';
import { useLocation, useOutlet } from 'react-router-dom';
import { CartProvider } from './contexts/CartContext';
import { SceneDesignerProvider } from './contexts/SceneDesignerContext';
import './CreateApp.scss';
import 'react-lazy-load-image-component/src/effects/opacity.css';
import { ReactComponent as Logo } from './assets/logo.svg';
import { ConfigContext } from "./contexts/ConfigContext";
import { ErrorReportingProvider } from "./contexts/ErrorReportingContext";
import { UIProvider } from "./contexts/UIContext";
import { AssetCartProvider } from "./contexts/AssetCartContext";

const AnimatedOutlet: React.FC = () => {
    const o = useOutlet();
    const [outlet] = useState(o);

    return <>{outlet}</>;
};

const CreateApp: React.FC = () => {
    const { pathname } = useLocation();
    const { availableClients, setCurrentClient, currentClient } = useContext(ConfigContext);

    const pageVariants: Variants = {
        initial: {
            y: '200%'
        },
        in: {
            y: 0,
            transition: {
                duration: 1,
                ease: 'easeInOut'
            }
        },
        out: {
            y: '-100%',
            transition: {
                duration: 0.5,
                ease: 'easeIn'
            }
        }
    };

    const transition = {
        type: 'tween',
        ease: 'easeInOut',
        duration: 0.5
    };

    return (
        <div id="createapp">
            {(availableClients.length > 1) && <div className='config-selector'>client:
                <select value={currentClient.id} onChange={e => { setCurrentClient(availableClients.find(c => c.id === parseInt(e.target.value))!) }}>
                    {availableClients.map(e => <option key={e.id} value={e.id}>{e.name}</option>)}
                </select>
            </div>}
            <div className='container'>
                <MotionConfig transition={transition}>
                    <UIProvider>
                        <ErrorReportingProvider>
                            <CartProvider>
                                <SceneDesignerProvider>
                                    <AssetCartProvider>
                                        <AnimatePresence mode='sync'>
                                            <motion.div
                                                className='page-container'
                                                key={pathname}
                                                initial="initial"
                                                animate="in"
                                                exit="out"
                                                variants={pageVariants}>
                                                <div className="loader before">
                                                    <Logo />
                                                </div>
                                                <div className="page">
                                                    <AnimatedOutlet />
                                                </div>

                                            </motion.div>
                                        </AnimatePresence>
                                    </AssetCartProvider>
                                </SceneDesignerProvider>
                            </CartProvider>
                        </ErrorReportingProvider>
                    </UIProvider>
                </MotionConfig>
            </div>
        </div>
    )
};

export default CreateApp;