import React, { useCallback, useContext, useEffect, useState } from "react";
import { BASE_PATH } from "../..";
import { ReactComponent as AddIcon } from '../../assets/icons/add.svg';
import { ReactComponent as DeleteIcon } from '../../assets/icons/delete.svg';
import { ReactComponent as NextIcon } from '../../assets/icons/arrow-right.svg';
import { ReactComponent as PrevIcon } from '../../assets/icons/arrow-left.svg';
import { SceneDesignerContext } from "../../contexts/SceneDesignerContext";
import "./AnimationEditor.scss";
import { VideoPlayer } from "./AnimationSelector";
import Drawer from 'react-modern-drawer';
import AssetList from "../layout/AssetList";
import { SceneCameraDTO } from "../../openapi/requests";
import { ReactComponent as CloseIcon } from '../../assets/icons/close.svg';
import { closestCenter, DndContext, DragEndEvent, KeyboardSensor, PointerSensor, useSensor, useSensors } from "@dnd-kit/core";
import { arrayMove, rectSortingStrategy, SortableContext, sortableKeyboardCoordinates, useSortable } from "@dnd-kit/sortable";
import { CSS } from '@dnd-kit/utilities';
import { AnimationShot } from "../../models/StillImageConfiguration";
import { makeRandomId } from "../../helpers/helpers";

interface Props {
    animationName: string;
    onClose: () => void;
}

interface CameraAsset extends SceneCameraDTO {
    name: string;
    title: string;
    isVideoThumbnail: boolean;
}

interface SortableItemProps {
    id: string;
    shot: AnimationShot;
    camera: SceneCameraDTO;
    onDelete: (id: string) => void;
    onMove: (id: string, direction: number) => void;
    progress: number;
}

const SortableItem: React.FC<SortableItemProps> = ({ id, camera, onDelete, onMove, progress }) => {
    const {
        attributes,
        listeners,
        setNodeRef,
        transform,
        transition,
    } = useSortable({ id: id });

    const style = {
        transform: CSS.Transform.toString(transform),
        transition,
    };

    return (
        <div ref={setNodeRef} className="video thumbnail" style={style} {...attributes} {...listeners}>
            {<video src={BASE_PATH + camera.animationPreview} />}
            <div className='buttons'>
                <button onClick={() => onMove(id, -1)}><PrevIcon /></button>
                <button onClick={() => onDelete(id)}><DeleteIcon /></button>
                <button onClick={() => onMove(id, 1)}><NextIcon /></button>
            </div>
            <div className="progress">
                <div className="bar" style={{width: `${(progress * 100)}%`}}></div>
            </div>
        </div>
    );
};




const AnimationEditor: React.FC<Props> = ({ onClose, animationName }) => {
    const { configuration, template, setConfiguration } = useContext(SceneDesignerContext);
    const [selectedCameras, setSelectedCameras] = useState<CameraAsset[]>([]);
    const [selectorOpen, setSelectorOpen] = useState(false);
    const [shots, setShots] = useState<SortableItemProps[]>([]);
    const sensors = useSensors(
        useSensor(PointerSensor, {
            activationConstraint: {
                distance: 8
            }
        }),
        useSensor(KeyboardSensor, {
            coordinateGetter: sortableKeyboardCoordinates,
        })
    );

    const handleDelete = useCallback((id: string) => {
        setShots((items) => {
            return items.filter(e => e.id !== id);
        });
    }, []);

    const handleMove = useCallback((id: string, direction: number) => {
        setShots((items) => {
            const oldIndex = items.findIndex(e => e.id === id);
            return arrayMove(items, oldIndex, oldIndex + direction);
        });
    }, []);

    useEffect(() => {
        let animation = configuration.animations.find(e => e.Name === animationName);

        if (animation) {
            setShots(animation.Shots.map(shot => {
                const cam = template?.scene.cameras.find(c => c.cameraName === shot.CameraName);

                return {
                    id: makeRandomId(12),
                    camera: cam!,
                    shot: shot,
                    onDelete: handleDelete,
                    onMove: handleMove,
                    progress: 0
                }
            }));
        } else {
            setShots([]);
        }

    }, [animationName, configuration.animations, handleDelete, handleMove, template?.scene.cameras]);



    const cameras: CameraAsset[] = template?.scene.cameras.filter(e => e.isAnimated).map(c => ({
        ...c,
        name: c.cameraName,
        title: c.label,
        isVideoThumbnail: true,
        thumbnail: c.animationPreview
    })) ?? [];

    const handleDragEnd = useCallback((event: DragEndEvent) => {
        const { active, over } = event;

        if (over) {
            if (active.id !== over.id) {
                setShots((items) => {
                    const oldIndex = items.findIndex(e => e.id === active.id);
                    const newIndex = items.findIndex(e => e.id === over.id);

                    return arrayMove(items, oldIndex, newIndex);
                });
            }
        }
    }, []);

    const handleCancel = useCallback(() => {
        setSelectorOpen(false);
    }, []);

    const handleSubmit = useCallback(() => {
        const newShots: SortableItemProps[] = selectedCameras.map(c => ({
            camera: c,
            id: makeRandomId(12),
            onDelete: handleDelete,
            onMove: handleMove,
            progress: 0,
            shot: {
                CameraName: c.cameraName,
                EndFrame: c.animationEnd,
                StartFrame: c.animationStart
            }
        }));
        setShots((shots) => [...shots, ...newShots]);
        setSelectorOpen(false);
    }, [handleDelete, handleMove, selectedCameras]);

    const handleSave = useCallback(() => {
        let animation = configuration.animations.find(e => e.Name === animationName);

        if(animation){
            animation = {...animation, Shots: shots.map(e => e.shot)}
            setConfiguration({...configuration, animations: configuration.animations.map(e => e.Name === animationName ? animation! : e)});
        }

        onClose();
    }, [animationName, configuration, onClose, setConfiguration, shots]);

    const handleProgress = useCallback((index: number, progress: number) => {
        setShots(shots => shots.map((e, i) => ({...e, progress: ((i === index ? progress : (i < index ? 1 : 0)))})));
    }, []);

    return (
        <>
            <div className="animationEditor">
                <div className="player">
                    <div className="player-container">
                        <VideoPlayer src={shots.map(e => BASE_PATH + e.camera.animationPreview)} onProgress={handleProgress} />
                    </div>
                </div>
                <div className="editor">
                    <h3>Sequences</h3>
                    <div className="shots">
                        <DndContext
                            sensors={sensors}
                            collisionDetection={closestCenter}
                            onDragEnd={handleDragEnd}
                        >
                            <SortableContext
                                items={shots}
                                strategy={rectSortingStrategy}
                            >
                                {shots.map(shot => <SortableItem key={shot.id} {...shot} />)}
                            </SortableContext>
                        </DndContext>
                        <button className="addnew" onClick={() => setSelectorOpen(true)}><AddIcon /></button>
                    </div>
                </div>
                <div className="save">
                    <button className="secondary button" onClick={handleSave}>Save</button>
                </div>
            </div>

            <Drawer className='productselector' open={selectorOpen} onClose={handleCancel} direction='right' size={530} overlayOpacity={0} enableOverlay={false}>
                <div className='header'>
                    <h1>Select item</h1>
                    <button className='close' onClick={handleCancel}>
                        <CloseIcon />
                    </button>
                </div>

                <AssetList<CameraAsset>
                    assets={cameras}
                    onSelectionChanged={setSelectedCameras}
                    selection={selectedCameras}
                    allowMultiSelect={true}
                    className='productselector-items'
                    displayMode='medium' />

                <div className='footer'>
                    <button className='submit' onClick={handleSubmit}>
                        Add
                    </button>
                </div>
            </Drawer>
        </>
    )
};

export default AnimationEditor;