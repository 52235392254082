import { BASE_PATH } from "..";

export const makeRandomId = (length: number): string => {
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    let counter = 0;
    while (counter < length) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
        counter += 1;
    }
    return result;
}

export const getAbsoluteUrl = (path: string): string => {
    if (path.includes("://")) {
        return path;
    }
    return BASE_PATH + path;
}