/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { LoginModel } from '../models/LoginModel';
import type { Token } from '../models/Token';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class AuthenticateService {

    /**
     * @returns any Success
     * @throws ApiError
     */
    public static getApiAuthenticatePing(): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/Authenticate/ping',
        });
    }

    /**
     * @param requestBody 
     * @returns Token Success
     * @throws ApiError
     */
    public static postApiAuthenticateLogin(
requestBody?: LoginModel,
): CancelablePromise<Token> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/Authenticate/login',
            body: requestBody,
            mediaType: 'application/json;odata.metadata=minimal;odata.streaming=true',
            errors: {
                401: `Unauthorized`,
            },
        });
    }

    /**
     * @param requestBody 
     * @returns Token Success
     * @throws ApiError
     */
    public static postApiAuthenticateRefresh(
requestBody?: Token,
): CancelablePromise<Token> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/Authenticate/refresh',
            body: requestBody,
            mediaType: 'application/json;odata.metadata=minimal;odata.streaming=true',
            errors: {
                401: `Unauthorized`,
            },
        });
    }

    /**
     * @param code 
     * @returns Token Success
     * @throws ApiError
     */
    public static postApiAuthenticateCallback(
code?: string,
): CancelablePromise<Token> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/Authenticate/callback',
            query: {
                'code': code,
            },
            errors: {
                401: `Unauthorized`,
            },
        });
    }

}
